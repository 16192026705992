import React from 'react';
import Card from 'react-bootstrap/Card';

import { VideoModal } from './VideoModal';
import { Button } from '@material-ui/core';
import Col from 'react-bootstrap/Col';
import { FaVideo } from 'react-icons/fa';

//import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';

export function VideoCard(props) {
	const [modalShow, setModalShow] = React.useState(false);

	return (
		<Card className="videoCard">
			<Card.Img variant="top" src={props.thumbnail} />

			<Card.ImgOverlay>
				<FaVideo className="videoCardIcon" />
				<Button
					className="videoButton"
					onClick={() => setModalShow(true)}
				>
				</Button>
			</Card.ImgOverlay>
			

			<VideoModal show={modalShow} onHide={() => setModalShow(false)} src={props.src} title={props.title} />
		</Card>
	);
}
