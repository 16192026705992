import React from 'react';
import Fade from '@material-ui/core/Fade';

export function Banner(props) {
	return (
		<div className="parent">
			<div className="overlay">
				<img className="bannerImage" id="aboutImage" src={props.img} alt="Harrow Steel" style={props.bannerStyle}/>
			</div>
			<Fade in={true} timeout={1500}>
				<h1 id="bannerh1" style={props.style}>{props.title}</h1>
			</Fade>
		</div>
	);
}
