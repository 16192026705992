import React from 'react';
import Card from 'react-bootstrap/Card';

import { PhotoModal } from './PhotoModal';
import { Button } from '@material-ui/core';
import Col from 'react-bootstrap/Col';

export function PhotoCard(props) {
	const [ modalShow, setModalShow ] = React.useState(false);

	return (
		<Card className="photoCard">
			<Card.Img src={props.thumbnail} />

			<Card.ImgOverlay>

				<Button
					className="photoButton"
					onClick={() => setModalShow(true)}
				>
				</Button>
			</Card.ImgOverlay>
			<PhotoModal show={modalShow} onHide={() => setModalShow(false)} src={props.thumbnail} title={props.title} />
		</Card>
	);
}
