import React, { useEffect, useState } from 'react';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { Testimonials } from './pages/Testimonials';
import MainNav from './components/MainNav';
import { Footer } from './components/Footer';
import { Photos } from './pages/Photos';
import { Videos } from './pages/Videos';
import { Contact } from './pages/Contact';
import { OurMission } from './pages/About Us/OurMission';
import { OurStaff } from './pages/About Us/OurStaff';
import { OurHistory } from './pages/About Us/OurHistory';
import { Trustees } from './pages/About Us/Trustees';
import { Support } from './pages/Support';
import { Youth } from './pages/Projects/Youth';
import { Senior } from './pages/Projects/Senior';
import { Summer2020 } from './pages/Projects/Summer2020';
import { Upcoming } from './pages/Projects/Upcoming';
import { Gallery } from './pages/Gallery';
import { Previous } from './pages/Projects/Previous';
import { GenericPage } from './components/GenericPage'
import axios from 'axios';
import banner from './images/pans2.jpg';


export function App(props) {
	const [navToggle, setNavToggle] = useState(0);
	const [pages, setPages] = useState(1);

	function navToggleCallback(toggle) {
		setNavToggle(toggle);
	}

	let headerStyle;
	let bannerStyle;
	let bodyStyle;

	if (navToggle === false) {
		headerStyle = { display: "none" };
		bannerStyle = { height: "100vh", overflowY: "hidden" };
		document.body.style.overflow = "hidden";

	} else {
		headerStyle = { display: "block" };
		bannerStyle = { height: "40vh" };
		document.body.style.overflowY = "visible";
		document.body.style.overflowX = "hidden";
	}

	useEffect(() => {
		axios.get('https://harrowsteel.wpcomstaging.com/wp-json/wp/v2/posts')
			.then(function (res) {
				updateState(res.data);
			})
			.catch(err => console.log(err))
	}, []);

	const updateState = res => {
		setPages(res);
	}

	return (
		<Router style={bodyStyle}>
			<MainNav navToggleCallback={navToggleCallback} />
			<Switch>
				<Route exact path="/">
					<Home style={headerStyle} bannerStyle={bannerStyle} />
				</Route>
				<Route path="/testimonials">
					<Testimonials style={headerStyle} bannerStyle={bannerStyle} />
				</Route>
				<Route path="/contact">
					<Contact style={headerStyle} bannerStyle={bannerStyle} />
				</Route>
				<Route path="/gallery">
					<Gallery style={headerStyle} bannerStyle={bannerStyle} />
				</Route>
				<Route path="/upcoming">
					<Upcoming style={headerStyle} bannerStyle={bannerStyle} />
				</Route>
				<Route path="/past">
					<Previous style={headerStyle} bannerStyle={bannerStyle} />
				</Route>
				{Array.isArray(pages)
					? pages.map(page =>
						page.status =='publish' ?
						<Route key={page.id} path={'/' + page.slug}>
							<GenericPage 
								style={headerStyle} 
								bannerStyle={bannerStyle} 
								title={page.title.rendered} 
								banner={page.acf.header_image ? page.acf.header_image : banner} 
								content={page.content.rendered}/>
						</Route>
						: null
					) : null}
			</Switch>
			<Footer />
		</Router>
	);
}


export default App;
