import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../images/Harrow Steel Transparent.png';
import johnLyonLogo from '../images/john_lyon_logo_final.png'
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import { Container } from '@material-ui/core';

export function Footer() {
	return (
		<Row as="footer">
			<Container className="footerContainer">
				<Col className="footerCol justify-content-left footerColLeft" md={3}>
					<img src={logo} alt="Harrow Steel Logo" className="footerLogo" />
				</Col>
				{/*
				<Col className="footerCol" md={3}>
					<a href="https://www.jlc.london/">
						<img className="johnLyonFooter" src={johnLyonLogo} />
					</a>
				</Col>
				*/}
				<Col className="footerCol" md={4} offset={1}>
					<h4 className="pt-2">Contact Us</h4>
					<a className="linkInheritColor" href="mailto:bandmanager@harrowsteel.org.uk">
						<p className="mb-0">bandmanager@harrowsteel.org.uk</p>
					</a>
				</Col>
				<Col className="footerCol footSocial" md={2} offset={1}>
					<p className="mb-0">
						<a className="footerIcons" href="https://www.instagram.com/harrowsteel/" target="_blank" rel="noopener noreferrer">
							<InstagramIcon />
						</a>
						<a className="footerIcons" href="https://twitter.com/steelharrow" target="_blank" rel="noopener noreferrer">
							<TwitterIcon />
						</a>
					</p>
				</Col>
			</Container>
		</Row>
	);
}
