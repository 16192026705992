import React from 'react';
import { Container } from '@material-ui/core';
import banner from '../images/pans3.jpg';
import ademola from '../images/testimonial photos/Ademola.jpg';
import nathan from '../images/testimonial photos/nathan.jpg';
import umisha from '../images/testimonial photos/Umisha.jpg';
import profile1 from '../images/placeholders/placeholder.png'
import { Banner } from '../components/Banner';
import DocumentMeta from 'react-document-meta';
import {TestimonialsWP} from '../components/WP/Testimonials'

export function Testimonials(props) {

	const meta={
		title: 'Harrow Steel | Testimonials',
		description:
			'Read up on what Harrow Steel members past and present have to say about the band.'
	
	}

	return (
		<DocumentMeta {...meta}>
			<Banner img={banner} title="Harrow Steel Member Testimonials" style={props.style} bannerStyle={props.bannerStyle}/>
			<Container className="bodyContainer">
				<TestimonialsWP />
			</Container>
		</DocumentMeta>
	);
}
