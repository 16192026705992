import React from 'react';
import { Banner } from '../components/Banner';
import { Container } from '@material-ui/core';
import { VideoCard } from '../components/Gallery Components/VideoCard';
import { PhotoCard } from '../components/Gallery Components/PhotoCard';
import { Button } from '@material-ui/core';
import CardDeck from 'react-bootstrap/CardDeck';
import Row from 'react-bootstrap/Row';
import DocumentMeta from 'react-document-meta';
import pans1 from '../images/pans1.jpg';
import pans2 from '../images/pans2.jpg';
import pans3 from '../images/pans3.jpg';
import pans4 from '../images/pans4.jpg';
import pans5 from '../images/pans5.jpg';
import pans6 from '../images/pans6.jpg';
import pans7 from '../images/pans7.jpg';
import pans8 from '../images/pans8.jpg';
import pans9 from '../images/pans9.jpg';
import pans10 from '../images/pans10.jpg';
import pans11 from '../images/pans11.jpg';
import pans12 from '../images/pans12.jpg';
import pans13 from '../images/pans13.jpg';
import InstagramIcon from '@material-ui/icons/Instagram';
import { Media } from '../components/WP/Media';

export function Gallery(props) {
	const meta = {
		title: 'Harrow Steel | Gallery',
		description:
			'Harrow Steel is a charity based in North-West London that works with young people to develop self-confidence, self-esteem and musicianship skills through playing Steel Pans.'

	}
	return (
		<DocumentMeta {...meta}>
			<Banner img={require('../images/pans4.jpg')} title="Gallery" style={props.style} bannerStyle={props.bannerStyle} />
			<Container className="mediaContainer">
				{/*
				<Row className="contactRow">
					<CardDeck>
						<PhotoCard
							thumbnail={pans13}
						/>
						<hr className='galleryDivider' />
						<PhotoCard
							thumbnail={pans12}
						/>
						<hr className='galleryDivider' />
						<PhotoCard
							thumbnail={pans11}
						/>
						<hr className='galleryDivider' />
					</CardDeck>
				</Row>

				<Row className="contactRow">
					<CardDeck>
						<VideoCard
							title="Royal Festival Hall - March 2020"
							src="https://www.youtube.com/embed/sN7wlwwgHiE"
							thumbnail={require('../images/Video Thumbnails/Royal Festival Hall 2020.jpg')}
						/>
						<hr className='galleryDivider' />
						<PhotoCard
							thumbnail={pans1}
						/>
						<hr className='galleryDivider' />
						<PhotoCard
							thumbnail={pans9}
						/>
						<hr className='galleryDivider' />
					</CardDeck>
				</Row>
				<Row className="contactRow">
					<CardDeck>
						<PhotoCard
							thumbnail={pans8}
						/>
						<hr className='galleryDivider' />
						<VideoCard
							title="Pinner Memorial Park - 2019"
							src="https://www.youtube.com/embed/cWwQZs1Qmzo"
							thumbnail={require('../images/Video Thumbnails/Pinner Memorial Park 2019.jpg')}
						/>
						<hr className='galleryDivider' />
						<PhotoCard
							thumbnail={pans7}
						/>
						<hr className='galleryDivider' />
					</CardDeck>
				</Row>
				<Row className="contactRow">
					<CardDeck>
						<PhotoCard
							thumbnail={pans6}
						/>
						<hr className='galleryDivider' />
						<PhotoCard
							thumbnail={pans4}
						/>
						<hr className='galleryDivider' />
						<PhotoCard
							thumbnail={pans3}
						/>
						<hr className='galleryDivider' />
					</CardDeck>
				</Row>
				<Row className="contactRow">
					<CardDeck>
						<VideoCard
							title="Royal Festival Hall - February 2018"
							src="https://www.youtube.com/embed/ivmlCig1lis"
							thumbnail={require('../images/Video Thumbnails/Royal Festival Hall 2018.jpg')}
						/>
						<hr className='galleryDivider' />
						<PhotoCard
							thumbnail={pans2}
						/>
						<div className='card emptyCard'/>
					</CardDeck>
				</Row>
				*/}
				<Media />
			</Container>
		</DocumentMeta>
	);
}