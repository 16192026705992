import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import ImageIcon from '@material-ui/icons/Image';
import VideocamIcon from '@material-ui/icons/Videocam';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

class MainNav extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			navExpand: false,
			pages: []
		};

		//this.getPosts = this.getPosts.bind(this)
	}

	toggle() {
		let navSwitch = !this.state.navExpand;

		this.setState({
			navExpand: navSwitch
		});

		this.props.navToggleCallback(this.state.navExpand);
	}

	getPosts = () =>{
		var self = this; 
		axios.get('https://harrowsteel.wpcomstaging.com/wp-json/wp/v2/posts')
			.then(function (res) {
				self.setState({
					pages: res.data
				});
			}).catch (err => console.log(err));
	}

	render() {
		this.getPosts();


		return (
			<Navbar
				collapseOnSelect
				className="navMain"
				onToggle={() => this.toggle()}
				expanded={this.state.navExpand}
				expand="lg"
				variant="dark"
			>
				<Navbar.Brand />
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav" className="justify-content-right">
					<Nav className="mr-auto">
						<Nav.Link eventKey="1" as={Link} id="navLink" to="/">
							Home <HomeIcon className="navIcons" />
						</Nav.Link>
						<NavDropdown
							title={
								<span>
									About Us <InfoIcon className="navIcons" />
								</span>
							}
							id="basic-nav-dropdown"
						>
							{Array.isArray(this.state.pages)
								? this.state.pages.map(page =>
									page.status == 'publish' && page.acf.navigation_category == 'About Us'
									? <NavDropdown.Item as={Link} id="navLink" to={page.slug} key={page.id}>
										{page.title.rendered}
									</NavDropdown.Item>
								:null)
							:null}
						</NavDropdown>
						<Nav.Link eventKey="3" as={Link} id="navLink" to="testimonials">
							Testimonials <RecordVoiceOverIcon className="navIcons" />
						</Nav.Link>
						<Nav.Link eventKey="4" as={Link} id="navLink" to="gallery">
							Gallery <ImageIcon className="navIcons" />
						</Nav.Link> 
						<NavDropdown
							title={
								<span>
									Events and Projects <DoneAllIcon className="navIcons" />
								</span>
							}
							id="basic-nav-dropdown"
						>
							<NavDropdown.Item as={Link} id="navLink" to="upcoming" eventKey="5.3">
								Upcoming Events
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} id="navLink" to="past" eventKey="5.3">
								Past Events
							</NavDropdown.Item>
							{Array.isArray(this.state.pages)
								? this.state.pages.map(page =>
									page.status == 'publish' && page.acf.navigation_category == 'Events and Projects'
									? <NavDropdown.Item as={Link} id="navLink" to={page.slug} key={page.id}>
										{page.title.rendered}
									</NavDropdown.Item>
								:null)
							:null}
						</NavDropdown>
						<Nav.Link as={Link} to="support-us" id="navLink" eventKey="6">
							Support Us <AccountBalanceIcon className="navIcons" />
						</Nav.Link>
						<Nav.Link eventKey="7" as={Link} id="navLink" to="contact">
							Contact <QuestionAnswerIcon className="navIcons" />
						</Nav.Link>
					</Nav>
					<Nav className="ml-auto">
						<Nav.Link>Charity Number: 1186948</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	}
}

export default withRouter(MainNav);

							/*<NavDropdown.Item as={Link} id="navLink" to="senior" eventKey="5.1">
								Harrow Steel Senior Band
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} id="navLink" to="summer" eventKey="5.2">
								Harrow Steel Summer School
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} id="navLink" to="youth" eventKey="5.3">
								Harrow Steel Community Youth Project
							</NavDropdown.Item>
							*/