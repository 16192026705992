import React from 'react'
import DocumentMeta from 'react-document-meta';
import { Banner } from '../components/Banner';
import { Container } from '@material-ui/core';
import parse from 'html-react-parser';

export function GenericPage(props) {

    const meta = {
        title: `Harrow Steel | ${props.title}`,
		description:
			'Harrow Steel is a charity based in North-West London that works with young people to develop self-confidence, self-esteem and musicianship skills through playing Steel Pans.'
    }

  return (
    <DocumentMeta {...meta}>
        <Banner img={props.banner} title={props.title} style={props.style} bannerStyle={props.bannerStyle} />
        <Container className="bodyContainer">
            {parse(props.content)}
        </Container>
    </DocumentMeta>
  )
}
