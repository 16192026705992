import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from '@material-ui/core';

export function PhotoModal(props) {
	return (
		<Modal {...props} size="lg" aria-labelledby="contained-model-title-vcenter" centered>
			<Modal.Body>
				<img
					title={props.title}
					className="photo"
					src={props.src}
					frameborder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen="allowfullscreen"
					mozallowfullscreen="mozallowfullscreen"
					msallowfullscreen="msallowfullscreen"
					oallowfullscreen="oallowfullscreen"
					webkitallowfullscreen="webkitallowfullscreen"
				/>
			</Modal.Body>

		</Modal>
	);
}
