import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { VideoCard } from '../Gallery Components/VideoCard';
import { PhotoCard } from '../Gallery Components/PhotoCard';
import { VideoModal } from '../Gallery Components/VideoModal';
import { PhotoModal } from '../Gallery Components/PhotoModal';

export function Media() {
  const [media, setMedia] = useState(0);

  useEffect(() => {
    axios.get('https://harrowsteel.wpcomstaging.com/wp-json/wp/v2/photo_video?per_page=100')
      .then(function (res) {
        updateState(res.data);
      })
      .catch(err => console.log(err))
  },[]);

  const updateState = res => {
    setMedia(res);

    const dateState = res.map(obj => {
      var objDate = new Date(obj.acf.upload_date).getTime();
      return { ...obj, upload_date: objDate }
    })

    var sortedState = dateState.sort((a, b) => b.upload_date - a.upload_date);


    setMedia(sortedState);
  }

  return (
    <div className="dynamicMediaRow">
      {Array.isArray(media)
        ? media.map(post =>
          post.acf.media_type == "Photo" ?
            <PhotoCard key={post.id} thumbnail={post.acf.image} /> :
            <VideoCard key={post.id} thumbnail={post.acf.image} src={post.acf.URL} />
        ) : null}
    </div>
  )
}
