import React from 'react';
import { Banner } from '../components/Banner';
import { Container } from '@material-ui/core';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import EmailIcon from '@material-ui/icons/Email';
import DocumentMeta from 'react-document-meta';

export function Contact(props) {
    const meta={
        title: 'Harrow Steel | Contact',
		description:
			'Harrow Steel is a charity based in North-West London that works with young people to develop self-confidence, self-esteem and musicianship skills through playing Steel Pans.'
	
    }

    function dataLayerPush(cat, act, lab){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'ga_event',
            'event_category': cat,
            'event_action': act,
            'event_label': lab
        });

    }

	return (
		<DocumentMeta {...meta}>
			<Banner img={require('../images/pans1.jpg')} title="Contact Us" style={props.style} bannerStyle={props.bannerStyle}/>
			<Container className="mediaContainer">
				<Row className="contactRow">
					<Col md={{span: 8, offset: 2}} sm={12}>
						<div className="contactBox" id="emailUs">
                            <EmailIcon className="contactIcon" />
							<p><span className="contactText">Send us an Email</span></p>
							<p><span className="contactText bold"><a href="mailto:bandmanager@harrowsteel.org.uk" onClick={dataLayerPush('Contact Click', 'Email', 'Contact Page')}>bandmanager@harrowsteel.org.uk</a></span></p>
						</div>
					</Col>
				</Row>
                <Row className="contactRow">
                    <Col md={{span:8, offset:2}} sm={12} className="socialMediaBox">
                    <div className="contactBox">
                        <AlternateEmailIcon className="contactIcon" />
                        <p><span className="contactText">Find us on Social Media</span></p>
                        <Row>
                            <Col md={true} sm={12}>
                                <p><span className="contactText">Twitter</span></p>
                                <p><span className="contactText bold"><a href="https://twitter.com/steelharrow" onClick={dataLayerPush('Social Click', 'Twitter', 'Contact Page')}>@SteelHarrow</a></span></p>
                            </Col>                           
                            <Col md={true} sm={12}>
                                <p><span className="contactText">Instagram</span></p>
                                <p><span className="contactText bold"><a href="https://www.instagram.com/harrowsteel/" onClick={dataLayerPush('Social Click', 'Instagram', 'Contact Page')}>@HarrowSteel</a></span></p>
                            </Col>
                        </Row>
                    </div>
                    </Col>
                </Row>
			</Container>
		</DocumentMeta>
	);
}


/*
<Col md={4} sm={12}>
                                <p><span className="contactText">Facebook</span></p>
                                <p><span className="contactText bold">Harrow Steel</span></p>
                            </Col>
                            */
