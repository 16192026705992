import React, { useState, useEffect } from 'react';
import axios from 'axios';

export function Events(props) {
    const [events, setEvents] = useState(0);

    //call wordpress API for events
    useEffect(() => {
        axios.get('https://harrowsteel.wpcomstaging.com/wp-json/wp/v2/events?per_page=100')
            .then(function (res) {
                updateState(res.data);
            })
            .catch(err => console.log(err))
    }, []);

    //function to sort and filter the response
    const updateState = (res) => {
        //acquire current date in UTC time
        var currentDate = new Date().getTime();

        //convert the date value in the response to UTC time
        const dateState = res.map(obj => {
            var objDate = new Date(obj.acf.date).getTime();
            return { ...obj, date: objDate }
        })

        //delete any events in the past or future based on props
        const deletedState = dateState.filter(obj => {
            if (props.filter == "upcoming") {
                return obj.date > currentDate
            } else {
                return obj.date < currentDate;
            }
        })

        //sort based on props
        if (props.filter == "upcoming") {
            var sortedState = deletedState.sort((a, b) => a.date - b.date);
        } else {
            var sortedState = deletedState.sort((a, b) => b.date - a.date);
        }

        //set the state to the sorted & filtered state
        setEvents(sortedState);
    }

    return (
        <div>
            {Array.isArray(events)
                ? events.map(myEvent =>
                    <div key={myEvent.id}>
                        <p><span className="strong">{myEvent.title.rendered}</span>
                            <br />
                            {myEvent.acf.date}
                            <br />
                            {myEvent.acf.location}</p>
                        <hr />
                    </div>
                ) : null}

            {events.length == 0 ?
                <p className="noUpcomingEvents">There are no upcoming events. Check back soon!</p>
                : null}
        </div>
    )

}