import React, { useState, useEffect } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';

export function TestimonialsWP() {
  const [testimonials, setTestimonials] = useState(0);

  useEffect(() => {
    axios.get('https://harrowsteel.wpcomstaging.com/wp-json/wp/v2/testimonial')
      .then(function (res) {
        updateState(res.data);
      })
      .catch(err => console.log(err))
  }, []);

  const updateState = res => {
    var left = false;


    const breakFix = res.map(obj => {
      left = !left


      var textBreak = obj.acf.testimonial_text.replace('<br />', '</p> <p>')
      textBreak = '<p className="pt-1">' + textBreak + '</p>';

      var occupationBreak = obj.acf.testimonial_occupation.replace('<br />', '</p> <p>')
      occupationBreak = '<p>' + occupationBreak + '</p>'


      return { ...obj, testimonial_text: textBreak, left: left, occupation: occupationBreak }
    })

    setTestimonials(breakFix);

  } 

  return (
    <div className="dynamicTestimonial">
      {Array.isArray(testimonials)
        ? testimonials.map(testimonial =>
          <span key={testimonial.id}>{parse(testimonial.testimonial_text)}
            <div className={testimonial.left == true ? "testimonial-left pb-1" : "testimonial-right pb-1"}>
              <img className='testimonial-profile rounded-circle' src={testimonial.acf.image ? testimonial.acf.image : "https://harrowsteel.wpcomstaging.com/wp-content/uploads/2022/10/placeholder.png"} />
              <div className={testimonial.left == true ? "testimonial-inner left" : "testimonial-inner right"}>
                <p className="testimonial-name">{testimonial.title.rendered}</p>
                {parse(testimonial.occupation)}
              </div>
            </div>
            <hr />
          </span>
        ) : null}
    </div>
  )
}
