import React, { useEffect } from 'react';
import { Container } from '@material-ui/core';
import banner from '../../images/pans13.jpg';
import { Banner } from '../../components/Banner';
import DocumentMeta from 'react-document-meta';
import { Events } from '../../components/WP/Events';

export function Upcoming(props) {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const meta = {
		title: 'Harrow Steel | Upcoming Events',
		description:
			'Harrow Steel is a charity based in North-West London that works with young people to develop self-confidence, self-esteem and musicianship skills through playing Steel Pans.'
	};

	return (
		<DocumentMeta {...meta}>
			<Banner
				img={banner}
				title="Upcoming Events"
				style={props.style}
				bannerStyle={props.bannerStyle}
			/>
			<Container className="bodyContainer">
				<p>After a long period of “Covid” restrictions we are keen to get back into the community to perform. Harrow
					Steel Musicians have been working hard to create a brilliant programme of music and we are proud to
					share this in the following local events:</p>
				<Events filter="upcoming"/>
			</Container>
		</DocumentMeta>
	);
}
