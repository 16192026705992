import React, { useEffect } from 'react';
import { Container } from '@material-ui/core';
import banner from '../../images/pans13.jpg';
import { Banner } from '../../components/Banner';
import DocumentMeta from 'react-document-meta';
import { Events } from '../../components/WP/Events';

export function Previous(props) {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const meta = {
		title: 'Harrow Steel | Past Events',
		description:
			'Harrow Steel is a charity based in North-West London that works with young people to develop self-confidence, self-esteem and musicianship skills through playing Steel Pans.'
	};

	return (
		<DocumentMeta {...meta}>
			<Banner
				img={banner}
				title="Previous Events"
				style={props.style}
				bannerStyle={props.bannerStyle}
			/>
			<Container className="bodyContainer">
				<p>After coming out of lockdown we've really enjoyed being able to perform again. We've had a great time recently at these local events:</p>
				<Events filter="past"/>
			</Container>
			
		</DocumentMeta>
	);
}
