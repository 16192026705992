import React from 'react';
import banner from '../images/pans2.jpg';
import logo from '../images/Harrow Steel Transparent.png';
import Fade from '@material-ui/core/Fade';
import { Container } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import bameMusic from '../images/Charity Logos/bame-music-network.jpg'
import harrowGiving from '../images/Charity Logos/harrow-giving.png'
import harrowMusicService from '../images/Charity Logos/harrow-music-service.jpg'
import youngHarrow from '../images/Charity Logos/young-harrow.png'
import johnLyonLogo from '../images/john_lyon_logo_final.png'

export class Home extends React.Component {
	render() {
		const meta = {
			title: 'Harrow Steel | Home',
			description:
				'Harrow Steel is a charity based in North-West London that works with young people to develop self-confidence, self-esteem and musicianship skills through playing Steel Pans.'
		};
		return (
			<DocumentMeta {...meta}>
				<div className="parent">
					<div className="overlay">
						<img className="bannerImage" src={banner} alt="Harrow Steel" style={this.props.bannerStyle} />
					</div>
					<Fade in={true} timeout={1500}>
						<img src={logo} alt="Harrow Steel Logo" id="homeLogo" style={this.props.style} />
					</Fade>
				</div>
				<Container className="mainContainer">
					<h1>Who Are We?</h1>
					<article>
						<p className='homeMainText'>“Harrow Steel” is a charity steel band. We were founded in 2019 and are based in the London Borough Harrow. We create an ethos of challenge and nurture that provides an opportunity for gifted and talented young musicians aged 13 – 21 to learn complex music aurally within a Steel Band.</p>
						<p className='homeMainText'>We welcome a variety of young people from a range of backgrounds. Our aim is to give these young people a sense of belonging, whilst teaching leadership skills and teamwork, which will stay with those in our community as they progress throughout their lives.</p>

						<Link to="/support">
							<Button variant="contained" color="secondary" size="large" className="homeButton">
								Support Us
							</Button>
						</Link>
						<br />
						<br />
						<p className='homeMainText'>To join the band or register your interest please click here:</p>
						<a href='https://linktr.ee/harrowsteel' target='_blank'>
							<Button variant='contained' color='primary' size='large' className='homeButtonSecondary'>
								Join Us
							</Button>
						</a>
					</article>
					<hr />
					<h2 className='HomeSubheading'>Upcoming Events</h2>
					<article>
						<p>After a long period of “Covid” restrictions we are keen to get back into the community to perform. Harrow
							Steel Musicians have been working hard to create a brilliant programme of music and we are proud to
							share this in some upcoming local events.</p>
						<p>Click the button below to find out about upcoming events:</p>
						<Link to='/upcoming'>
							<Button variant='contained' color='secondary' size='medium' className='homeButton'>
								Find out More
							</Button>
						</Link>
					</article>
					<hr />
					<article>
					<h2>Sponsored by</h2>
						<div className='homeCharityContainer'>

							<a href="https://harrowgiving.org.uk/" className='homeCharityLogos'><img src={harrowGiving} /></a>
							<a href="https://www.jlc.london/"><img className='homeCharityLogos' src={johnLyonLogo} /></a>

							<img src={bameMusic} className='homeCharityLogos' />
							<a href="https://youngharrowfoundation.org/" className='homeCharityLogos'><img src={youngHarrow} /></a>
							<a href="https://www.harrowmusic.org/" className='homeCharityLogos'><img src={harrowMusicService} /></a>
							<h3 className='homeCharityLogos'>Woodward Trust</h3>
							<h3 className='homeCharityLogos'>Gunnersbury Lodge</h3>
						</div>

					</article>
				</Container>
			</DocumentMeta>
		);
	}
}
